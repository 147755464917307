<template>
  <div>
    <resolve-ticket-sidebar />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <ticket-list-filters
        ref="filters"
        :search="search"
      />
      <div class="mb-1 ml-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-dropdown
              v-if="selected.length > 0"
              class="mx-1"
              variant="primary"
              right
              text="Opções"
            >
              <b-dropdown-item 
                @click="updateStatuses(supportTicketStatus.PENDING)"
              >
                <feather-icon icon="ZapIcon" />
                <span class="align-middle ml-50">Marcar como lido</span>
              </b-dropdown-item>
              <b-dropdown-item 
                @click="updateStatuses(supportTicketStatus.UNREAD)"
              >
                <feather-icon icon="ZapOffIcon" />
                <span class="align-middle ml-50">Marcar como não lido</span>
              </b-dropdown-item>
              <b-dropdown-item 
                @click="solveTickets"
              >
                <feather-icon icon="CheckCircleIcon" />
                <span class="align-middle ml-50">Resolver tickets</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontradas solução financeiras para esta busca"
        :busy="loading"
        @row-clicked="goToTicketDetails"
      >
        <!-- Column: check -->
        <template #cell(selected)="data">
          <div class="text-wrap">
            <b-form-checkbox
              v-model="selected"
              :value="data.item.id"
              @click.native.stop
            />
          </div>
        </template>
        <!-- Column: Cliente -->
        <template #cell(customer_name)="data">
          <div class="text-wrap">
            <span class="align-text-top ">{{
              data.item.customer_name
            }}</span>
          </div>
        </template>
        <!-- Column: PN -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.consultant_name
            }}</span>
          </div>
        </template>
        <!-- Column: VIP -->
        <template #cell(is_vip)="data">
          <div class="text-wrap">
            <img
              v-if="data.item.is_vip"
              class="img_icon"
              src="~@/assets/images/svg/icn_premium.svg"
            />
          </div>
        </template>
        <!-- Column: Franquia -->
        <template #cell(franchise_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.franchise_name
            }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <div class="text-wrap">
            <b-badge
              pill
              :variant="statusBadgeVariant(data.item.status_id)"
            >
              {{ data.item.status }}
            </b-badge>
          </div>
        </template>
        <!-- Column: Data de criação -->
        <template #cell(created_at)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.created_at | dateTime
            }}</span>
          </div>
        </template>
        <!-- Column: Prazo -->
        <template #cell(time_elapsed)="data">
          <div class="text-wrap">
            <span class="align-text-top ">{{
              data.item.time_elapsed 
            }}h</span>
          </div>
        </template>
        <!-- Column: Responsável -->
        <template #cell(read_by)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.reader_name
            }}</span>
          </div>
        </template>
        <!-- Column: Tag -->
        <template #cell(type)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.type
            }}</span>
          </div>
        </template>
        <template #cell(product)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.product_name
            }}</span>
          </div>
        </template>
        <!-- Column: Descrição -->
        <template #cell(description)="data">
          <div class="text-wrap align-text-top">
            <span v-if="data.item.type_id == supportTicketTypes.COMMENT">
              Novo comentário: <span class="text-primary">{{ data.item.comment }}</span>
            </span>
            <div v-if="data.item.type_id == supportTicketTypes.CONTRACT_STATUS">
              O contrato mudou para o status: <span class="text-primary"> {{ data.item.contract_status_name }}</span>
            </div>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="
              updateFinancialSolutionEnabled || deleteFinancialSolutionEnabled
            "
            variant="link"
            no-caret
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item 
              v-if="data.item.status_id == supportTicketStatus.UNREAD"
              @click="updateStatus(data.item.id, supportTicketStatus.PENDING)"
            >
              <feather-icon icon="Edit3Icon" />
              <span class="align-middle ml-50">Marcar como lido</span>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="data.item.status_id == supportTicketStatus.PENDING"
              @click="updateStatus(data.item.id, supportTicketStatus.UNREAD)"
            >
              <feather-icon icon="Edit3Icon" />
              <span class="align-middle ml-50">Marcar como não lido</span>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="data.item.status_id == supportTicketStatus.PENDING"
              @click="resolveTicket(data.item.id)"
            >
              <feather-icon icon="Edit3Icon" />
              <span class="align-middle ml-50">Resolver ticket</span>
            </b-dropdown-item>
            
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import * as supportTicketTypes from "@/constants/support_ticket_types";
import * as supportTicketStatus from "@/constants/support_ticket_status";
import ResolveTicketSidebar from "../components/ResolveTicketSidebar";
import TicketListFilters from "../components/TicketListFilters";
import {
  CREATE_ACTION,
  FINANCIAL_SOLUTION_RESOURCE,
  UPDATE_ACTION,
  DELETE_ACTION
} from "@/constants/resources";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    TicketListFilters,
    ResolveTicketSidebar
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      supportTicketStatus,
      supportTicketTypes,
      loading: false,
      tableColumns: [
        {key: "selected", label: "", sortable: false},
        { key: "customer_name", label: "Cliente", sortable: true },
        { key: "consultant_name", label: "Solicitante", sortable: true },
        { key: "is_vip", label: "VIP", sortable: true },
        { key: "franchise_name", label: "Franqueado", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "created_at", label: "Data de criação", sortable: true },
        { key: "time_elapsed", label: "Prazo", sortable: true },
        { key: "read_by", label: "Responsável" },
        { key: "type", label: "Tag" },
        { key: "product", label: "Produto" },
        { key: "description", label: "Notificação" },
        { key: "actions", label: "Ações" },
      ],
      currentPage: 1,
      itemsPerPage: 1,
      selected: [],
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.SUPPORT_TICKETS_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
    updateFinancialSolutionEnabled: function () {
      return this.$can(UPDATE_ACTION, FINANCIAL_SOLUTION_RESOURCE);
    },
    deleteFinancialSolutionEnabled: function () {
      return this.$can(DELETE_ACTION, FINANCIAL_SOLUTION_RESOURCE);
    },
    createFinancialSolutionEnabled: function () {
      return this.$can(CREATE_ACTION, FINANCIAL_SOLUTION_RESOURCE);
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      searchTickets: types.SEARCH_SUPPORT_TICKETS,
      setStatus: types.SET_STATUS,
      openResolveTicketSidebar: types.OPEN_RESOLVE_TICKET_SIDEBAR
    }),
    search(currentPage) {
      this.loading = true;
      this.selected = [];
      const {
        rangeDate,
        consultant,
        franchise,
        structureOption,
        ticketType,
        status,
        isVip
      } = this.$refs.filters;
      var appointmentStart = undefined;
      var appointmentEnd = undefined;
      if (rangeDate) {
        appointmentStart = rangeDate.split(" até ")[0];
        appointmentEnd = rangeDate.split(" até ")[1];
      }
      this.searchTickets({
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
        appointment_start: appointmentStart,
        appointment_end: appointmentEnd,
        ticket_type: ticketType,
        consultant_id: consultant,
        franchise,
        structure_option: structureOption,
        status,
        is_vip: isVip
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os chamados. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToTicketDetails(ticket, index) {
      this.$router.push({
        name: "ticket-details-summary",
        params: { id: ticket.id },
      });
    },
    resolveTicket(idTicket) {
      this.openResolveTicketSidebar({
        id: idTicket,
        saveAction: this.search,
      });
    },
    solveTickets(idTicket) {
      this.openResolveTicketSidebar({
        id: this.selected,        
        saveAction: this.search,
      });
    },
    statusBadgeVariant(status) {
      switch(status) {
        case supportTicketStatus.UNREAD:
          return "danger"
        case supportTicketStatus.PENDING:
          return "warning"
        case supportTicketStatus.SOLVED:
          return "success"
        default: 
          return "danger"
      }
    },
    updateStatus(id, status){
      var payload = {
        ticket_id: id,
        status_id: status
      }
      this.setStatus({
        ...payload
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao mudar o status do ticket. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.search();
          this.loading = false;
        });
    },
    updateStatuses(status){
      if(this.selected.length > 0){ 
        var payload = {
          ticket_ids: this.selected,
          status_id: status
        }
        this.setStatus({
        ...payload
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Status atualizado com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.search();
          this.selected = [];
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao mudar o status do ticket. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.search();
          this.loading = false;
        });

      }
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.img_icon {
  width: 30px;
  height: 30px;
}
</style>
