var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-header',{staticClass:"pb-50"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('h5',[_vm._v("Filtros")])])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Parceiro de negócio (PN)","label-for":"searchConsultant"}},[_c('v-select',{attrs:{"id":"searchConsultant","multiple":"","reduce":function (consultant_list) { return consultant_list.id; },"options":_vm.consultantList,"loading":_vm.loading.consultantList,"close-on-select":false,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"input":_vm.consultantFranchiseChange},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.consultant),callback:function ($$v) {_vm.consultant=$$v},expression:"consultant"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Franqueado","label-for":"searchFranchise"}},[_c('v-select',{attrs:{"id":"searchFranchise","multiple":"","reduce":function (franchise_list) { return franchise_list.id; },"options":_vm.franchises,"loading":_vm.loading.franchises,"close-on-select":false,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"input":_vm.consultantFranchiseChange},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.franchise),callback:function ($$v) {_vm.franchise=$$v},expression:"franchise"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Período","label-for":"searchRangeDate"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"searchRangeDate","config":_vm.flatPickrConfig,"placeholder":("01 Jan 2020 até 31 Dez " + _vm.actualYear)},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Origem de ticket","label-for":"searchTicketType"}},[_c('v-select',{attrs:{"id":"searchTicketType","multiple":"","reduce":function (ticketType) { return ticketType.id; },"options":_vm.ticketTypes,"loading":_vm.loading.ticketTypes,"label":"description"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.ticketType),callback:function ($$v) {_vm.ticketType=$$v},expression:"ticketType"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Tipo de visualização","label-for":"searchStructureOption"}},[_c('v-select',{attrs:{"id":"searchStructureOption","reduce":function (structure_list) { return structure_list.key; },"options":_vm.structures,"loading":_vm.loading.structures,"label":"name","disabled":_vm.hasConsultantFranchiseSelected,"clearable":false},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.structureOption),callback:function ($$v) {_vm.structureOption=$$v},expression:"structureOption"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"searchTicketStatus"}},[_c('v-select',{attrs:{"id":"searchTicketStatus","multiple":"","reduce":function (ticketStatus) { return ticketStatus.id; },"options":_vm.ticketStatus,"loading":_vm.loading.statuses,"label":"description"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"switch":""},model:{value:(_vm.isVip),callback:function ($$v) {_vm.isVip=$$v},expression:"isVip"}},[_c('span',[_vm._v("Apenas VIPs")])])],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}}),_c('b-col',{staticClass:"mb-2 text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-secondary","disabled":_vm.loading.list || _vm.spinnerActive},on:{"click":function($event){$event.preventDefault();return _vm.clearSearch.apply(null, arguments)}}},[_vm._v(" Limpar ")]),_c('b-button',{attrs:{"variant":"outline-primary","disabled":_vm.loading.list || _vm.spinnerActive},on:{"click":function($event){$event.preventDefault();return _vm.search(1)}}},[(_vm.loading.list || _vm.spinnerActive)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SearchIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.loading.list || _vm.spinnerActive ? "Aguarde..." : "Pesquisar")+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }