<template>
  <div>
    <b-card-header class="pb-50">
      <div class="d-flex justify-content-start">
        <h5>Filtros</h5>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group
            label="Parceiro de negócio (PN)"
            label-for="searchConsultant"
          >
            <v-select
              id="searchConsultant"
              multiple
              v-model="consultant"
              :reduce="(consultant_list) => consultant_list.id"
              :options="consultantList"
              :loading="loading.consultantList"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
              @input="consultantFranchiseChange"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Franqueado" label-for="searchFranchise">
            <v-select
              id="searchFranchise"
              multiple
              v-model="franchise"
              :reduce="(franchise_list) => franchise_list.id"
              :options="franchises"
              :loading="loading.franchises"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
              @input="consultantFranchiseChange"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Período" label-for="searchRangeDate">
            <flat-pickr
              id="searchRangeDate"
              v-model="rangeDate"
              class="form-control"
              :config="flatPickrConfig"
              :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Origem de ticket" label-for="searchTicketType">
            <v-select
              id="searchTicketType"
              multiple
              v-model="ticketType"
              :reduce="(ticketType) => ticketType.id"
              :options="ticketTypes"
              :loading="loading.ticketTypes"
              label="description"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Tipo de visualização"
            label-for="searchStructureOption"
          >
            <v-select
              id="searchStructureOption"
              v-model="structureOption"
              :reduce="(structure_list) => structure_list.key"
              :options="structures"
              :loading="loading.structures"
              label="name"
              @keyup.enter="search"
              :disabled="hasConsultantFranchiseSelected"
              :clearable="false"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group
            label="Status"
            label-for="searchTicketStatus"
          >
            <v-select
              id="searchTicketStatus"
              multiple
              v-model="status"
              :reduce="(ticketStatus) => ticketStatus.id"
              :options="ticketStatus"
              :loading="loading.statuses"
              label="description"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-checkbox
            class="mt-2"
            switch
            v-model="isVip"
          >
            <span>Apenas VIPs</span>
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" class="text-right"> </b-col>
        <b-col cols="12" class="mb-2 text-right">
          <b-button
            variant="outline-secondary"
            @click.prevent="clearSearch"
            class="mr-1"
            :disabled="loading.list || spinnerActive"
          >
            Limpar
          </b-button>
          <b-button
            variant="outline-primary"
            @click.prevent="search(1)"
            :disabled="loading.list || spinnerActive"
          >
            <b-spinner v-if="loading.list || spinnerActive" small />
            <feather-icon v-else icon="SearchIcon" class="mr-50" />
            <span>
              {{ loading.list || spinnerActive ? "Aguarde..." : "Pesquisar" }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BSpinner,
  BPopover,
  BCardText,
} from "bootstrap-vue";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import VueSlider from "vue-slider-component";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { OWN, STRUCTURE } from "@/constants/structure_options";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    vSelect,
    BCardText,
    BPopover,
    DynamicSelect,
    VueSlider,
    flatPickr
  },
  props: {
    search: Function,
    spinnerActive: Boolean
  },
  data() {
    return {
      loading: {
        customers: false,
        ticketTypes: false,
        consultantList: false,
        franchises: false,
        structures: false,
        statuses: false
      },
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      customer: undefined,
      rangeDate: undefined,
      ticketType: undefined,
      consultant: undefined,
      franchise: undefined,
      structureOption: OWN,
      status: undefined,
      isVip: false
    };
  },
  setup() {
    const actualYear = new Date().getFullYear()
    return { toast: useToast(), actualYear };
  },
  computed: {
    ...mapGetters({
      customers: sharedTypes.CUSTOMERS,
      consultantList: sharedTypes.CONSULTANTS,
      franchises: sharedTypes.FRANCHISES,
      structures: sharedTypes.STRUCTURE_TYPES,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      ticketTypes: types.TICKET_TYPES,
      ticketStatus : types.TICKET_STATUS
    }),
    hasConsultantFranchiseSelected: function () {
      return (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      );
    },
  },
  mounted() {
    this.loading.ticketTypes = true;
    this.getTicketTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os tipos de compromisso para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.ticketTypes = false;
      });
    this.loading.ticketStatus = true;
    this.getTicketStatus()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.ticketStatus = false;
      });
    this.loading.consultantList = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os parceiros de negócio para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultantList = false;
      });
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as franquias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
    this.loading.structures = true;
    this.getStructures()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as estruturas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.structures = false;
      });
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.rangeDate =
        this.$route.query.startDate + " até " + this.$route.query.endDate;
      this.search();
    }
  },
  methods: {
    ...mapActions({
      getCustomers: sharedTypes.GET_CUSTOMERS,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getStructures: sharedTypes.GET_STRUCTURE_TYPES,
      getTicketTypes: types.GET_TICKET_TYPES,
      getTicketStatus: types.GET_TICKET_STATUS
    }),
    consultantFranchiseChange() {
      if (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      ) {
        this.structureOption = STRUCTURE;
      }
    },
    clearSearch() { 
      this.customer = null;
      this.rangeDate = null;
      this.ticketType = null;
      this.consultant = null;
      this.franchise = null;
      this.structureOption = OWN;
      this.isVip = false;
      this.search(1);
    }
  },
};
</script>

<style lang="scss">
$themeColor: #f26237;
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-slider-component/lib/theme/default.scss";
.form-group {
  margin-bottom: 0;
}
</style>
